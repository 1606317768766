<template>
	<v-form ref="form">
		<v-layout column>
			<w-section :title="$t('holding.admin-team.informations')">
				<v-layout row wrap>
					<v-flex xs12>
						<w-email-input v-model="email" :label="$t('holding.admin-team.email')" maxlength="191" required />
					</v-flex>
					<v-layout row wrap>
						<v-flex pr-2 xs12 sm6>
							<w-text-input
								ref="firstname"
								v-model="firstname"
								:disabled="!canEditUserInfo"
								:label="$t('holding.admin-team.firstname')"
								maxlength="191"
								required
							/>
						</v-flex>
						<v-flex xs12 sm6>
							<w-text-input
								ref="lastname"
								v-model="lastname"
								:disabled="!canEditUserInfo"
								:label="$t('holding.admin-team.lastname')"
								maxlength="191"
								required
							/>
						</v-flex>
					</v-layout>
				</v-layout>
			</w-section>
			<w-section :title="$t('holding.admin-team.access')">
				<v-layout row wrap>
					<v-flex xs12>
						<w-switch v-model="isHoldingAdmin" :hide-details="false" :persistent-hint="isHoldingAdmin" :hint="$t('holding.admin-team.welyb_admin_access_info')" :label="$t('holding.admin-team.welyb_admin_access')" />
					</v-flex>
					<v-flex xs12>
						<w-switch v-model="hasAllAccesses" :label="$t('holding.admin-team.all_access')" />
					</v-flex>
					<v-layout v-if="hasAllAccesses" row wrap>
						<v-flex ml-5 xs12>
							<v-radio-group v-model="accessRole" style="width: 100%" :label="$t('holding.admin-team.role') + '*'" :disabled="!hasAllAccesses">
								<template v-for="role in roles">
									<w-radio :key="role.key" :value="role.key" :label="role.value" />
								</template>
							</v-radio-group>
						</v-flex>
						<v-flex xs12>
							<w-switch v-model="sendInvitation" :label="$t('holding.admin-team.send_to_user')" :disabled="!hasAllAccesses" />
						</v-flex>
					</v-layout>
				</v-layout>
			</w-section>
			<v-layout row>
				<v-spacer />
				<w-btn-save :disabled="!isFormValid" :loading="loading" @click="onAdd()">{{ $t('holding.admin-team.add') }}</w-btn-save>
			</v-layout>
		</v-layout>
	</v-form>
</template>
<script>
import Validator from '@/mixins/Validator'
import HoldingUserModuleGuard from '@/mixins/ModulesGuards/Holding/HoldingUserModuleGuard'

export default {
	name: 'HoldingUserCreateForm',
	mixins: [Validator, HoldingUserModuleGuard],
	inject: ['holdingContext'],
	props: {
		inDialog: {
			default: false,
			required: false,
			type: Boolean
		}
	},
	data: function () {
		return {
			roles: [
				{
					key: 'admin',
					value: this.$t('holding.admin-team.admin')
				},
				{
					key: 'collaborator',
					value: this.$t('holding.admin-team.collaborator')
				}
			],
			hasAllAccesses: false,
			accessRole: null,
			email: '',
			lastname: '',
			firstname: '',
			isHoldingAdmin: false,
			sendInvitation: false,
			canEditUserInfo: false,
			loading: false
		}
	},
	computed: {
		holdingId: function () {
			return this.holdingContext?.holding_id
		},
		isFormValid: function () {
			return (
				this.validateEmail(this.email) &&
				this.validateRequired(this.lastname) &&
				this.validateRequired(this.firstname) &&
				((this.hasAllAccesses && [this.roles[0].key, this.roles[1].key].includes(this.accessRole)) || !this.hasAllAccesses)
			)
		}
	},
	watch: {
		email: {
			handler: function (val) {
				if (!val) {
					return
				}

				if (!this.validateEmail(val)) {
					this.canEditUserInfo = false
					this.resetFirstnameAndLastname()
					return
				}

				this.getUserByEmail(val)
					.then(users => {
						const user = users?.[0] ?? null
						if (user) {
							this.canEditUserInfo = false
							this.firstname = user.firstname
							this.lastname = user.lastname
						} else {
							if (this.canEditUserInfo) {
								return
							}
							this.canEditUserInfo = true
							this.resetFirstnameAndLastname()
						}
					})
			}
		},
		hasAllAccesses: {
			handler: function (val) {
				if (!val) {
					this.resetData()
				}
			}
		}
	},
	methods: {
		resetForm: function () {
			this.$refs.form.reset()
			this.canEditUserInfo = false
			if (this.inDialog) {
				this.$emit('canceled')
			}
		},
		resetFirstnameAndLastname: function () {
			this.firstname = ''
			this.lastname = ''
			this.$refs.lastname.$children[0].reset()
			this.$refs.firstname.$children[0].reset()
		},
		resetData: function () {
			this.accessRole = null
			this.sendInvitation = false
		},
		getUserByEmail: function (email) {
			return this.service.getUserByEmail({email})
		},
		onAdd: function () {
			this.loading = true
			let data = {
				email: this.email,
				firstname: this.firstname,
				lastname: this.lastname,
				isHoldingAdmin: this.isHoldingAdmin,
				hasAllAccesses: this.hasAllAccesses
			}

			if (this.hasAllAccesses) {
				data = { ...data, accessRole: this.accessRole, sendInvitation: this.sendInvitation }
			}

			return this.service
				.createHoldingUsers(this.holdingId, data)
				.then(() => {
					this.$emit('created')
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('holding.admin-team.user_added'))
					this.resetForm()
				})
				.finally(() => {
					this.loading = false
				})
		}
	}
}
</script>